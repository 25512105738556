

import { Vue, Component } from "vue-property-decorator";

import { EmployeeService } from "@/services/employee";
import { CreateEmployeeDTO } from "@/services/employee/employee.dto";

@Component
export default class AddEmployee extends Vue {
  name = "";
  address = "";
  contactNumber = "";

  data() {
    return {
      name: "",
      address: "",
      contactNumber: ""
    };
  }

  goBack() {
    this.$router.back();
  }

  created() {
    this.$store.commit("liveData/setSelectedActivity", 2);
    this.$store.commit("liveData/setSelectedActivityName", "Employees");
  }

  async saveEmployee() {
    try {
      const employeeToAdd: CreateEmployeeDTO = {
        name: this.name,
        address: this.address,
        contactNumber: this.contactNumber
      };
      await EmployeeService.createEmployee(employeeToAdd);
      this.goBack();
    } catch (err) {
      console.log(err);
    }
  }
}
