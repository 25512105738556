import {
  CreateEmployeeDTO,
  EmployeeDTO,
  UpdateEmployeeDTO
} from "./employee.dto";
import * as Repository from "./employee.repository";

async function createEmployee(
  employeeToAdd: CreateEmployeeDTO
): Promise<EmployeeDTO> {
  const employee: EmployeeDTO = await Repository.createEmployee(employeeToAdd);

  return employee;
}

async function getEmployeeById(employeeId: string): Promise<EmployeeDTO> {
  const employee: EmployeeDTO = await Repository.findById(employeeId);

  return employee;
}

async function getEmployees(active: boolean): Promise<EmployeeDTO[]> {
  const employees: EmployeeDTO[] = await Repository.findEmployees(active);

  return employees;
}

async function updateEmployee(
  employeeId: string,
  employeeDetailsToUpdate: UpdateEmployeeDTO
): Promise<EmployeeDTO> {
  const employee: EmployeeDTO = await Repository.updateEmployee(
    employeeId,
    employeeDetailsToUpdate
  );

  return employee;
}

async function deleteEmployee(employeeId: string): Promise<void> {
  await Repository.deleteEmployee(employeeId);
}

export const EmployeeService = {
  createEmployee,
  getEmployeeById,
  getEmployees,
  updateEmployee,
  deleteEmployee
};
