import { Post, Get, Patch, Delete } from "../Repository";

import * as API from "./employee.api";
import {
  CreateEmployeeDTO,
  EmployeeDTO,
  UpdateEmployeeDTO
} from "./employee.dto";

async function createEmployee(
  employeeToAdd: CreateEmployeeDTO
): Promise<EmployeeDTO> {
  const response = await Post(`${API.route}`, employeeToAdd);

  return response as EmployeeDTO;
}

async function findById(employeeId: string): Promise<EmployeeDTO> {
  const response = await Get(`${API.route}/${employeeId}`);

  return response as EmployeeDTO;
}

async function findEmployees(active: boolean): Promise<EmployeeDTO[]> {
  const response = await Get(`${API.route}`, { active });

  return response as EmployeeDTO[];
}

async function updateEmployee(
  employeeId: string,
  employeeDetailsToUpdate: UpdateEmployeeDTO
): Promise<EmployeeDTO> {
  const response = await Patch(
    `${API.route}/${employeeId}`,
    employeeDetailsToUpdate
  );

  return response as EmployeeDTO;
}

async function deleteEmployee(employeeId: string): Promise<void> {
  await Delete(`${API.route}/${employeeId}`);
}

export {
  createEmployee,
  findById,
  findEmployees,
  updateEmployee,
  deleteEmployee
};
